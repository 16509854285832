import React from "react";
import Icon from "../Icon/Icon";
import "./Notification.scss";


export default function Notification(props) {
    const type = props.content.type ? props.content.type : "info";
    const text = props.content.text ? <p className="notification-content">{props.content.text}</p> : null

    return (
        <vstack className={"notification-container notification-type-" + type} spacing="xxs">
            <hstack align-y="top">
                <span className="notification-title" stretch="">
                    {props.content.title}
                </span>
                <span onClick={(e) => props.onClose(e)}
                      className="notification-close">
                    <Icon icon="x" className="notification-close"/>
                </span>
            </hstack>
            {text}
        </vstack>
    );
}