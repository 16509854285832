import React from "react";
import {BastionLaunchConfig, BreakGlassLaunchConfig, ConsoleLaunchConfig} from "../LaunchConfig/LaunchConfig";
import "./LaunchConfigSection.scss";
import Fuse from "fuse.js";


export class LaunchConfigSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openItem: null
        };
    }

    getSortedContent() {
        let content = this.props.content.slice();

        if (this.props.search) {
            const options = {
                includeScore: false,
                shouldSort: true,
                keys: ["name", "accountName", {name: "description", weight: 0.1}],
                threshold: 0.1
            };
            const fuse = new Fuse(content, options);
            const results = fuse.search(this.props.search);

            content = [];
            for (const key in results) {
                content.push(results[key].item);
            }
        }

        return content;
    }

    getContent() {
        const content_defs = this.getSortedContent();
        const content = [];

        for (const item_key in content_defs) {
            const item = content_defs[item_key];
            const type = item.type === "console" ? ConsoleLaunchConfig : BastionLaunchConfig;

            item.open = item.key === this.state.openItem && item.key !== null;
            item.onClick = () => this.onConfigClick(item.key);

            content.push(React.createElement(type, item));
        }

        return content;
    }

    onConfigClick(key) {
        if (this.state.openItem === key)
            key = null;

        this.setState({openItem: key});
    }

    render() {
        const content = this.getContent();

        // Divide content between columns
        const columns_content = [];
        for (let i = 0; i < content.length; i++) {
            const target_col = i % this.props.columns;
            const item = content[i];

            if (columns_content[target_col])
                columns_content[target_col].push(item);
            else
                columns_content[target_col] = [item];
        }

        let columns = [];
        for (let col in columns_content) {
            columns.push((
                <vstack key={col} className="column" align-y='top' spacing='xs'>
                    {columns_content[col]}
                </vstack>
            ));
        }

        if (this.props.content.length === 0) {
            columns = [
                <span className="empty">Nothing to show.</span>
            ];
        }

        return (
            <vstack className="section">
                <span className="title">{this.props.title}</span>
                <div className="columns">
                    {columns}
                </div>
            </vstack>
        )
    }
}


export class BreakGlassLaunchConfigSection extends LaunchConfigSection {
    getSortedContent() {
        let content = this.props.content.slice();

        if (this.props.search) {
            const options = {
                includeScore: false,
                shouldSort: true,
                keys: ["name", "email", {name: "applications", weight: 0.1}],
                threshold: 0.1
            };
            const fuse = new Fuse(content, options);
            const results = fuse.search(this.props.search);

            content = [];
            for (const key in results) {
                content.push(results[key].item);
            }
        }

        return content;
    }

    getContent() {
        const content_defs = this.getSortedContent();
        const content = [];

        for (const item_key in content_defs) {
            const item = content_defs[item_key];

            item.open = item.key === this.state.openItem && item.key !== null;
            item.onClick = () => this.onConfigClick(item.key);

            content.push(React.createElement(BreakGlassLaunchConfig, item));
        }

        return content;
    }
}