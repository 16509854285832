import React from "react";
import "./Fields.scss";


export default class ReadOnlyTextField extends React.Component {
    render() {
        return (
            <vstack>
                <hstack className="field-group" spacing="s" align-y="top">
                    <span
                        className="field-label">{this.props.displayName ? this.props.displayName : this.props.name}</span>
                    <span className="field-value-ro" stretch="">{this.props.value}</span>
                </hstack>
            </vstack>
        )
    }
}
