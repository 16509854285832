import React from "react";
import Icon from "../Icon/Icon";
import "./IconButton.scss";
import "pyloncss/css/pylon.css";


export function IconButton(props) {
    return (
        <hstack align-x="center" align-y="center" className={"icon-button-bg icon-color-" + props.color}
                onClick={props.onClick}>
            <div style={props.style} className="icon-button-icon-div">
                <Icon icon={props.icon} width={props.size || 18} height={props.size || 18} className="icon-button-icon"/>
            </div>
        </hstack>
    )
}

export function PlayButton(props) {
    const _props = {
        onClick: props.onClick,
        icon: "play",
        color: "green",
        style: {marginLeft: "3px"}
    }

    return IconButton(_props)
}

export function LinkButton(props) {
    const _props = {
        onClick: props.onClick,
        icon: "link",
        color: "green",
    }

    return IconButton(_props)
}

export function CopyButton(props) {
    const _props = {
        onClick: props.onClick,
        icon: "clipboard",
        color: "green",
    }

    return IconButton(_props)
}

export function TerminalButton(props) {
    const _props = {
        onClick: props.onClick,
        icon: "terminal",
        color: "green",
    }

    return IconButton(_props)
}

export function LoadingButon(props) {
    return (
        <div className="icon-button-spin">
            <IconButton onClick={props.onClick} icon="loader" color="yellow"/>
        </div>
    )
}

export function StopButton(props) {
    const _props = {
        onClick: props.onClick,
        icon: "x",
        color: "red",
        size: 20
    }

    return IconButton(_props)
}

export function ForwardButton(props) {
    const _props = {
        onClick: props.onClick,
        icon: "globe",
        color: "green",
        // style: {marginLeft: "2px"}
    }

    return IconButton(_props)
}
