import React from "react";
import "./Fields.scss";
import moment from "moment";


export default class DateTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        };
    }

    onChange(e) {
        this.setState({"value": e.target.value});
        this.props.onChange(moment(e.target.value).toISOString());
    }

    render() {
        const valid = this.props.validation(this.state.value);

        return (
            <vstack className={valid ? "invalid" : null}>
                <hstack className="field-group" spacing="s" align-y="top">
                    <span
                        className="field-label">{this.props.displayName ? this.props.displayName : this.props.name}</span>
                    <vstack>
                        <input type="datetime-local"
                               value={moment(this.state.value).format("YYYY-MM-DDTHH:mm")}
                               onChange={(e) => this.onChange(e)}
                               stretch=""
                        />
                        <span className="validation">{valid}</span>
                    </vstack>

                </hstack>
            </vstack>
        )
    }
}
