import {AdminSidebar} from "../components/Sidebar/Sidebar";
import React from "react";
import call from "../api";
import "pyloncss/css/pylon.css";
import ItemTable from "../components/ItemTable/ItemTable";
import BaseApp from "./BaseApp";

export default class AdminList extends BaseApp {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            content: []
        };
    }

    update() {
        this.setState({content: []});
        this.fetchContent();
    }

    fetchContent() {
        call("GET",
            `generic/${this.props.type}`,
            (status, content) => {
                if (status !== 200) {
                    this.sendNotification("error", "Error while fetching content", content.message, 30);
                    return;
                }

                this.setState({"content": Object.values(content)})
            });
    }

    /*
     * Render
     */
    getContent() {
        return (<ItemTable
            columns={this.props.columns}
            data={this.state.content}
            title={this.props.title}
            search={this.state.search}
            noCreate={this.props.noCreate}
            onNew={(e) => {
            }}
            onRowClick={(e, item) => {
                window.location.href = `${window.location.href}/${item.path}`
            }}
        />);
    }

    getSidebar() {
        return (<AdminSidebar
            short={this.state.isMenuShort}
        />);
    }
}

