import React from "react";
import BaseEditItem from "./BaseEditItem";
import TextField from "../Fields/TextField";
import "./EditItem.scss";
import {PermissionSelectField} from "../Fields/DropdownSelectField";
import ListReadOnlyField from "../Fields/ListReadOnlyField";


export default class GroupEdit extends BaseEditItem {
    FIELDS = [
        {
            element: TextField,
            name: "name",
            validate: (val) => {
                return String(val).toLowerCase().match(
                    /^[a-zA-Z0-9_-]+$/
                ) ? null : "Group name can contain only letters, numbers, underscores, and dashes.";
            }
        },
        {
            element: TextField,
            name: "description",
        },
        {
            element: PermissionSelectField,
            name: "permissions"
        },
        {
            element: ListReadOnlyField,
            name: "users",
            genContent: (value) => {
                const result = [];

                for (const i in value) {
                    const item = value[i];

                    if (item.is_disabled)
                        continue;

                    result.push(<a href={`../${item.type}/${item.path}`} key={item.path}
                                   className="group-link">{item.name || item.username || item.email || item.path}</a>)
                }

                return result;
            },
            readOnly: true
        }
    ]

    getPath() {
        if (this.props.create)
            return `group.${this.state.item.name}`;

        return super.getPath();
    }
}
