import BaseEditItem from "./BaseEditItem";
import TextField from "../Fields/TextField";
import "./EditItem.scss";
import DropdownSelectField, {SingleItemDropdownField,} from "../Fields/DropdownSelectField";


export default class ConsoleAppEdit extends BaseEditItem {
    FIELDS = [
        {
            element: TextField,
            name: "name",
            validation: (value) => {
                if (!value)
                    return "Can't be empty";

                if (value.length > 50)
                    return "Too long";

                // if (value.search(" ") !== -1)
                //     return "Can't use spaces."
            }
        },
        {
            element: TextField,
            name: "description",
        },
        {
            element: SingleItemDropdownField,
            name: "account",
            type: "account",
            validation: (value) => {
                if (!value)
                    return "Can't be empty";
            }
        },
        {
            element: DropdownSelectField,
            name: "policies",
            type: "policy",
            maxItems: 10,
            validation: (value) => {
                if (!value.length)
                    return "Can't be empty";
            }
        }
    ]
}
