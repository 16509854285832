import React from "react";
import {get_auth, goToLogin} from "../api";
import Icon from "../components/Icon/Icon";
import Notification from "../components/Notification/Notification";
import {Logo} from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import jwtDecode from "jwt-decode";

/* global DEBUG */

export default class BaseApp extends React.Component {
    DISPLAY_SEARCH = true;

    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            isMenuShort: false,
            search: null,
            modal: null,
            _path: window.location
        };
    }

    componentDidMount() {
        if (!DEBUG) {
            // Login: Save auth key from the URL
            const params = new URLSearchParams(window.location.hash.substring(1));
            if (params.has("id_token")) {
                localStorage.setItem("id_token", params.get("id_token"));

                const expires_at = Date.now() + parseInt(params.get("expires_in")) * 1000
                localStorage.setItem("expires_at", expires_at.toString());
            }

            // Login: Check if token is valid
            const token = localStorage.getItem("id_token");
            const token_expires_at = parseInt(localStorage.getItem("expires_at"));
            if (token === null || token_expires_at < Date.now()) {
                goToLogin();
                return;
            }
        }

        this.update();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState._path !== window.location.pathname) {
            this.setState({_path: window.location.pathname});
            this.update();
        }
    }

    update() {
    }

    getUser() {
        let decoded;
        try {
            decoded = jwtDecode(get_auth());
        } catch {
            return "";
        }

        if (decoded["cognito:username"])
            return decoded["cognito:username"];

        if (decoded["email"])
            return decoded["email"];
    }

    getCapabilities() {
        let decoded;
        try {
            decoded = jwtDecode(get_auth());
        } catch {
            return {
                "break_glass": false,
                "admin": false
            };
        }

        return JSON.parse(decoded["capabilities"]);
    }

    /*
     * Notifications
     */
    sendNotification(type, title, text, timeout = null) {
        const notifications = this.state.notifications.slice();
        notifications.push({
            "type": type,
            "title": title,
            "text": text,
            "timeout": timeout,
            "uuid": Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
        });
        this.setState({"notifications": notifications});
    }

    removeNotification(notification) {
        const new_state = [];
        for (const i in this.state.notifications)
            if (this.state.notifications[i].uuid !== notification.uuid)
                new_state.push(this.state.notifications[i])

        this.setState({"notifications": new_state})
    }

    startNotificationTimeout(notification) {
        if (notification.timeout)
            window.setTimeout(() => {
                this.removeNotification(notification)
            }, notification.timeout * 1000);
    }

    /*
     * Top and sidebar handling
     */

    onLogoClick() {
        // this.setState({isMenuShort: !this.state.isMenuShort});
        window.location.href = "/";
    }

    onSearch(event) {
        this.setState({
            search: event.target.value === "" ? null : event.target.value
        });
    }

    /*
     * Render
     */
    getSidebar() {
    }

    getContent() {
    }

    render() {
        /*
         * Modal
         */
        let modal = null;
        if (this.state.modal) {
            modal = (<div id="modal" key="modal">
                <vstack className="modal-content" spacing="xs">
                    <hstack spacing="xl">
                        <span className="modal-title">
                            {this.state.modal.title}
                        </span>
                        <spacer/>
                        <span onClick={() => {
                            this.setState({modal: null})
                        }}>
                            <Icon icon="x"/>
                        </span>
                    </hstack>
                    <vstack className="inner-content" spacing="xs">
                        {this.state.modal.content}
                    </vstack>
                </vstack>
            </div>)
        }

        /*
         * Notifications
         */
        const notifiactions = [];
        for (const i in this.state.notifications) {
            const notification = this.state.notifications[i];
            notifiactions.push(<Notification content={notification}
                                             onClose={(e) => {
                                                 this.removeNotification(notification)
                                             }}
                                             key={notification.uuid}/>);
            this.startNotificationTimeout(notification);
        }

        let notifications_content = null;
        if (notifiactions.length) {
            notifications_content = (
                <vstack id="notifications" key="notifications" spacing="m">
                    {notifiactions}
                </vstack>
            )
        }

        return [
            modal,
            notifications_content,
            <div id="App" key="app">
                <div id="grid-logo" onClick={() => this.onLogoClick()}>
                    <Logo short={this.state.isMenuShort}/>
                </div>
                <div id="grid-sidebar">
                    {this.getSidebar()}
                </div>
                <div id="grid-topbar">
                    <Topbar user={this.getUser()} onChange={(e) => this.onSearch(e)}
                            displaySearch={this.DISPLAY_SEARCH}/>
                </div>
                <div id="grid-content">
                    {this.getContent()}
                </div>
            </div>
        ];
    }
}