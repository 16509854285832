import BaseEditItem from "./BaseEditItem";
import TextField from "../Fields/TextField";
import "./EditItem.scss";
import DropdownSelectField, {SingleItemDropdownField, ResourceSelectField} from "../Fields/DropdownSelectField";
import call from "../../api";


export default class BastionAppEdit extends BaseEditItem {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            resources: [],
            amis: [],
            vpcs: []
        }
    }

    update() {
        super.update();
        this.getResources();
        this.getAmis();
        this.getVpcs();
    }

    getResources() {
        call(
            "GET",
            `generic/resource`,
            (status, content) => {
                if (status !== 200) {
                    alert(`Failed to list resources: ${content.message}`);
                    return;
                }

                let items = Object.values(content);
                this.setState({"resources": items});
            }
        );
    }

    getAmis() {
        call(
            "GET",
            `generic/ami`,
            (status, content) => {
                if (status !== 200) {
                    alert(`Failed to list amis: ${content.message}`);
                    return;
                }

                let items = Object.values(content);
                this.setState({"amis": items});
            }
        );
    }

    getVpcs() {
        call(
            "GET",
            `generic/vpc`,
            (status, content) => {
                if (status !== 200) {
                    alert(`Failed to list vpcs: ${content.message}`);
                    return;
                }

                let items = Object.values(content);
                this.setState({"vpcs": items});
            }
        );
    }

    FIELDS = [
        {
            element: TextField,
            name: "name",
            validation: (value) => {
                if (!value)
                    return "Can't be empty";

                if (value.length > 50)
                    return "Too long";

                // if (value.search(" ") !== -1)
                //     return "Can't use spaces.";
            }
        },
        {
            element: TextField,
            name: "description",
        },
        {
            element: SingleItemDropdownField,
            name: "vpc",
            displayName: "Launch VPC",
            type: "vpc",
            displayRegion: true,
            validation: (value) => {
                if (!value)
                    return "Can't be empty";
            }
        },
        {
            element: SingleItemDropdownField,
            name: "ami",
            displayName: "AMI",
            type: "ami",
            displayRegion: true,
            validation: (value) => {
                if (!value)
                    return "Can't be empty";

                if (typeof this.state.item.vpc === "undefined" || this.state.item.vpc === null)
                    return;

                const vpc_p = this.state.item.vpc.path || this.state.item.vpc;
                const vpc_i = this.state.vpcs.findIndex((vpc) => vpc.path === vpc_p);
                const vpc = this.state.vpcs[vpc_i];

                const ami_i = this.state.amis.findIndex((ami) => ami.path === value)
                const ami = this.state.amis[ami_i];

                if (ami.region !== vpc.region)
                    return "AMI's region differs from VPC's region"
            }
        },
        {
            element: DropdownSelectField,
            name: "policies",
            type: "policy",
            maxItems: 10
        },
        {
            element: TextField,
            displayName: "Static role ARN",
            name: "static_role",
            validation: (value) => {
                if (value) {
                    if (this.state.item.policies === "undefined" || this.state.item.policies === null)
                        return;

                    if (this.state.item.policies.length > 0)
                        return "Can't use static role ARN with policies. Use one or the other."
                }
            }
        },
        {
            element: ResourceSelectField,
            name: "resources",
            type: "resource",
            maxItems: 5,
            validation: (value) => {
                console.log(value)
                console.log(this.state.item.vpc)

                if (typeof this.state.item.vpc === "undefined" || this.state.item.vpc === null)
                    return;

                const vpc_p = this.state.item.vpc.path || this.state.item.vpc;
                const vpc_i = this.state.vpcs.findIndex((vpc) => vpc.path === vpc_p);
                const vpc = this.state.vpcs[vpc_i];

                if (value.length) {
                    for (const i in value) {
                        let resource = value[i];

                        if (typeof resource ===  "string") {
                            const resource_i = this.state.resources.findIndex((res) => res.path === resource);

                            if (resource_i === -1)
                                continue;

                            resource = this.state.resources[resource_i];
                        }

                        if (resource.vpc.path !== vpc.path)
                            return `Resource ${resource.name || resource.path} not in the selected VPC.`
                    }
                }
            }
        }
    ]
}
