import React from "react";
import "pyloncss/css/pylon.css";
import "./ModalCopyBox.js.scss";
import Icon from "../Icon/Icon";


export default function ModalCopyBox(props) {
    return (
        <hstack className="modal-copy-box" spacing="xxs" align-y="center">
            <div className="pre" stretch="">
                {props.text}
            </div>

            <hstack className="modal-copy-button" onClick={() => {
                navigator.clipboard.writeText(props.text)
            }} aria-label="Copy" align-y="top">
                <Icon icon="clipboard"/>
            </hstack>
        </hstack>
    )
}