import $ from "jquery";

/* global API_URL, COGNITO_DOMAIN, COGNITO_APP_ID, DEBUG, ID_TOKEN */

function login_url() {
    const app_url = `${window.location.protocol}//${window.location.hostname}`;
    return `https://${COGNITO_DOMAIN}/login?response_type=token&client_id=${COGNITO_APP_ID}&redirect_uri=${encodeURIComponent(app_url)}`;
}

function logout_url() {
    const app_url = `${window.location.protocol}//${window.location.hostname}`;
    return `https://${COGNITO_DOMAIN}/login?response_type=token&client_id=${COGNITO_APP_ID}&redirect_uri=${encodeURIComponent(app_url)}`;
}

export function goToLogin() {
    if (!DEBUG) {
        window.location = login_url();
    }
}

export function logout() {
    if (!DEBUG) {
        localStorage.clear();
        window.location = logout_url();
    }
}

export function get_auth() {
    if (!DEBUG)
        return localStorage.getItem("id_token");

    return ID_TOKEN;
}

function build_url(suffix) {
    return `${API_URL}/${suffix}`;
}

export default function call(method, url_path, callback, payload = {}) {
    const callback_wrapped = (jqXHR, textStatus) => {
        if (jqXHR.status === 401) {  // Auth expired
            goToLogin();
            return;
        }

        callback(jqXHR.status, jqXHR.responseJSON);
    };

    if (method === "GET")
        $.ajax({
            "method": method,
            url: build_url(url_path),
            async: true,
            complete: callback_wrapped,
            // data: payload,
            dataType: "json",
            headers: {
                Authorization: get_auth(),
            },
        });
    else
        $.ajax({
            "method": method,
            url: build_url(url_path),
            async: true,
            complete: callback_wrapped,
            data: JSON.stringify(payload),
            dataType: "json",
            headers: {
                Authorization: get_auth(),
            },
            // mimeType: "application/json"
        });
}
