import React from "react";
import Icon from "../Icon/Icon";
import "./Topbar.scss";


export default function Topbar(props) {
    return (
        <hstack className="topbar">
            <hstack className="search-box" style={{display: props.displaySearch ? null : "none"}}>
                <Icon icon="search" />
                <input stretch="" type="text" placeholder="Search applications..." onChange={(e) => props.onChange(e)}/>
            </hstack>

            <spacer/>

            <Icon icon="user"/>
            <span className="username">{props.user}</span>
        </hstack>
    )
}
