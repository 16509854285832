import React from "react";
import "pyloncss/css/pylon.css";
import "./ItemTable.scss";
import Fuse from "fuse.js";
import {Link} from "react-router-dom";


export default class ItemTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sorting: {
                column: this.props.columns[0],
                direction: "asc"
            }
        };
    }

    setSorting(column, direction) {
        this.setState({
            "sorting": {
                "column": column,
                "direction": direction
            }
        })
    }

    isSorting(column, direction) {
        return this.state.sorting.column === column && this.state.sorting.direction === direction;
    }

    itemToRepr(item) {
        let _item = item;

        if (Array.isArray(item)) {
            const parsed = [];
            for (const i in item)
                parsed.push(this.itemToRepr(item[i]));
            return parsed.join(", ")
        }

        if (typeof _item === 'object')
            _item = _item.name ? _item.name : _item.path

        if (typeof _item === 'undefined')
            return "";

        if (typeof _item !== 'string')
            _item = _item.toString()

        return _item
    }

    getContent() {
        let content = [];

        for (const row_i in this.props.data) {
            const row = this.props.data[row_i]
            const parsed_item = {};
            for (const cell_name_i in this.props.columns) {
                const cell_name = this.props.columns[cell_name_i];
                parsed_item[cell_name] = this.itemToRepr(row[cell_name]);
            }
            parsed_item.path = row.path;
            content.push(parsed_item);
        }

        // Search
        if (this.props.search) {
            const options = {
                includeScore: false,
                shouldSort: true,
                keys: this.props.columns
            };
            const fuse = new Fuse(content, options);
            const results = fuse.search(this.props.search);

            content = [];
            for (const key in results) {
                content.push(results[key].item);
            }
            return content;
        }

        // Sort
        const col = this.state.sorting.column;
        const sorted = content.sort((a, b) => `${a[col]}`.localeCompare(`${b[col]}`))

        if (this.state.sorting.direction === "desc")
            return sorted.reverse();
        return sorted;
    }

    getRow(item) {
        const row_content = [];
        for (const column_i in this.props.columns) {
            const column_name = this.props.columns[column_i];
            row_content.push(
                <td className="cell" key={item.path + column_name}>{item[column_name]}</td>
            );
        }

        return (
            <tr className="row" key={item.path} onClick={(e) => this.props.onRowClick(e, item)}>
                {row_content}
            </tr>
        )
    }

    getRows() {
        const rows = [];
        const content = this.getContent();
        for (const i in content)
            rows.push(this.getRow(content[i]));
        return rows;
    }

    getHeaders() {
        if (this.props.columns.indexOf(this.state.sorting.column) === -1) {
            this.setState({
                sorting: {
                    column: this.props.columns[0],
                    direction: "asc"
                }
            });
        }

        const header_cells = []
        for (const i in this.props.columns) {
            const column = this.props.columns[i];
            header_cells.push(
                <th className="header-cell" key={column}>
                    <hstack>
                        <span className="header-name">{column.replace("_", " ")}</span>
                        <vstack className="header-arrows" align-x="right">
                            <div className="arrow-up"
                                 onClick={(e) => this.setSorting(column, "asc")}
                                 aria-selected={this.isSorting(column, "asc")}
                            />
                            <div className="arrow-down"
                                 onClick={(e) => this.setSorting(column, "desc")}
                                 aria-selected={this.isSorting(column, "desc")}
                            />
                        </vstack>
                    </hstack>
                </th>
            )
        }

        return (
            <tr className="table-header">
                {header_cells}
            </tr>
        )
    }

    render() {
        const create_style = {display: this.props.noCreate ? "none" : null};

        return (
            <div className="item-table">
                <hstack className="header" align-x="center" align-y="center">
                    <div className="header-title">
                        {this.props.title}
                    </div>
                    <spacer/>
                    <Link to="new" className="header-button" style={create_style}>
                        Create new
                    </Link>
                </hstack>
                <table className="table">
                    <thead>
                    {this.getHeaders()}
                    </thead>
                    <tbody>
                    {this.getRows()}
                    </tbody>
                </table>
            </div>
        );
    }
}