import React, {useEffect} from "react";
import feather from "feather-icons";

export default function Icon(props) {
    useEffect(() => {
        feather.replace();
    });

    return (
        <i data-feather={props.icon} {...props}></i>
    );
}
