import React from "react";
import "./Fields.scss";


export default class TextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: (this.props.value ? this.props.value : "")
        }
    }

    onChange(e) {
        e.stopPropagation();
        this.setState({"value": e.target.value});
        this.props.onChange(e.target.value);
    }

    render() {
        const valid = this.props.validation(this.state.value);

        return (
            <vstack className={valid ? "invalid" : null}>
                <hstack className="field-group" spacing="s" align-y="top">
                    <span
                        className="field-label">{this.props.displayName ? this.props.displayName : this.props.name}</span>
                    <vstack>
                        <input type="text"
                               value={this.state.value}
                               onChange={(e) => this.onChange(e)}
                               stretch=""
                        />
                        <span className="validation">{valid}</span>
                    </vstack>

                </hstack>
            </vstack>
        )
    }
}


export class TextArea extends TextField {
    render() {
        const valid = this.props.validation(this.state.value);

        return (
            <vstack className={valid ? "invalid" : null}>
                <hstack className="field-group" spacing="s" align-y="top">
                    <span
                        className="field-label">{this.props.displayName ? this.props.displayName : this.props.name}</span>
                    <vstack>
                        <textarea
                            value={this.state.value}
                            onChange={(e) => this.onChange(e)}
                            stretch=""
                        />
                        <span className="validation">{valid}</span>
                    </vstack>

                </hstack>
            </vstack>
        )
    }
}


export class NumberField extends TextField {
    onChange(e) {
        e.stopPropagation();
        const value = parseInt(e.target.value);
        this.setState({"value": value});
        this.props.onChange(value);
    }

    render() {
        const valid = this.props.validation(this.state.value);

        return (
            <vstack className={valid ? "invalid" : null}>
                <hstack className="field-group" spacing="s" align-y="top">
                    <span
                        className="field-label">{this.props.displayName ? this.props.displayName : this.props.name}</span>
                    <vstack>
                        <input type="number"
                               value={this.state.value}
                               onChange={(e) => this.onChange(e)}
                               stretch=""
                        />
                        <span className="validation">{valid}</span>
                    </vstack>

                </hstack>
            </vstack>
        )
    }
}