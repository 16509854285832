import BaseEditItem from "./BaseEditItem";
import TextField from "../Fields/TextField";
import "./EditItem.scss";
import {ResourceTypeSelect, SingleItemDropdownField,} from "../Fields/DropdownSelectField";


export default class ResourceEdit extends BaseEditItem {
    FIELDS = [
        {
            element: TextField,
            name: "name",
            // validate: (val) => {
            //     if (val.search(" ") !== -1)
            //         return "Can't use spaces."
            // }
        },
        {
            element: TextField,
            name: "description",
        },
        {
            element: ResourceTypeSelect,
            name: "subtype",
            displayName: "type",
            validation: (value) => {
                if (value === null)
                    return "Can't be empty";
            }
        },
        {
            element: SingleItemDropdownField,
            name: "vpc",
            type: "vpc",
            validation: (value) => {
                if (value === null)
                    return "Can't be empty";
            }
        },
        {
            element: TextField,
            name: "tag_value",
            displayName: "Tag value",
            validation: (value) => {
                if (!value || value.length < 1)
                    return "Can't be empty";

                if (value.length > 255)
                    return "Too long";

                if (value.startsWith("aws:"))
                    return "Can't start with 'aws:'";
            }
        }
    ]
}
