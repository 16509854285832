import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import User from './pages/User';
import AdminList from "./pages/AdminList.js"
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import PathToProp from "./pages/PathMap";
import {PoolUserEdit, PoolUserNew} from "./components/EditItem/PoolUserEdit"
import AdUserEdit from "./components/EditItem/AdUserEdit";
import GroupEdit from "./components/EditItem/GroupEdit";
import ResourceEdit from "./components/EditItem/ResourceEdit";
import BastionAppEdit from "./components/EditItem/BastionAppEdit";
import ConsoleAppEdit from "./components/EditItem/ConsoleAppEdit";
import PolicyEdit from "./components/EditItem/PolicyEdit";
import BreakGlass from "./pages/BreakGlass";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<User/>}/>
            <Route path="/break_glass" element={<BreakGlass/>}/>

            <Route path="/admin/user-pool">
                <Route index element={
                    <AdminList title="Temporary Users"
                               columns={["username", "email", "groups", "expires_at"]}
                               type="user-pool"/>}/>
                <Route path="new" element={
                    <PathToProp element={PoolUserNew} name="Temporary user" type="user-pool" create={true}/>
                }/>
                <Route path=":path" element={
                    <PathToProp element={PoolUserEdit} name="Temporary user" type="user-pool"/>
                }/>
            </Route>

            <Route path="/admin/user-ad">
                <Route index element={
                    <AdminList title="Users"
                               columns={["username", "email", "groups"]}
                               type="user-ad"
                               noCreate={true}/>}/>
                <Route path=":path" element={
                    <PathToProp element={AdUserEdit} name="User" type="user-ad"/>
                }/>
            </Route>
            <Route path="/admin/group">
                <Route index element={
                    <AdminList title="Groups"
                               columns={["name", "description"]}
                               type="group"/>}/>
                <Route path="new" element={
                    <PathToProp element={GroupEdit} name="Group" type="group" create={true}/>
                }/>
                <Route path=":path" element={
                    <PathToProp element={GroupEdit} name="Group" type="group"/>
                }/>
            </Route>
            <Route path="/admin/policy">
                <Route index element={
                    <AdminList title="Policies"
                               columns={["name", "description"]}
                               type="policy"/>}/>
                <Route path="new" element={
                    <PathToProp element={PolicyEdit} name="Policy" type="policy" create={true}/>
                }/>
                <Route path=":path" element={
                    <PathToProp element={PolicyEdit} name="Policy" type="policy"/>
                }/>
            </Route>
            <Route path="/admin/resource">
                <Route index element={
                    <AdminList title="Resources"
                               columns={["name", "subtype", "vpc", "tag_value", "description"]}
                               type="resource"/>}/>
                <Route path="new" element={
                    <PathToProp element={ResourceEdit} name="Resource" type="resource" create={true}/>
                }/>
                <Route path=":path" element={
                    <PathToProp element={ResourceEdit} name="Resource" type="resource"/>
                }/>
            </Route>
            <Route path="/admin/console_cfg">
                <Route index element={
                    <AdminList title="Console Launch Configs"
                               columns={["name", "account", "policies", "description"]}
                               type="console_cfg"/>}/>
                <Route path="new" element={
                    <PathToProp element={ConsoleAppEdit} name="Console Application" type="console_cfg" create={true}/>
                }/>
                <Route path=":path" element={
                    <PathToProp element={ConsoleAppEdit} name="Console Application" type="console_cfg"/>
                }/>
            </Route>
            <Route path="/admin/bastion_cfg">
                <Route index element={
                    <AdminList title="Bastion Launch Configs"
                               columns={["name", "vpc", "resources", "description"]}
                               type="bastion_cfg"/>}/>
                <Route path="new" element={
                    <PathToProp element={BastionAppEdit} name="Bastion Application" type="bastion_cfg" create={true}/>
                }/>
                <Route path=":path" element={
                    <PathToProp element={BastionAppEdit} name="Bastion Application" type="bastion_cfg"/>
                }/>
            </Route>
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
