import BaseEditItem from "./BaseEditItem";
import TextField from "../Fields/TextField";
import "./EditItem.scss";
import DropdownSelectField from "../Fields/DropdownSelectField";


export default class AdUserEdit extends BaseEditItem {
    CAN_DELETE = false;

    FIELDS = [
        {
            element: TextField,
            name: "name",
            // validate: (val) => {
            //     if (val.search(" ") !== -1)
            //         return "Can't use spaces."
            // }
        },
        {
            element: TextField,
            name: "description",
        },
        {
            element: DropdownSelectField,
            name: "groups",
            type: "group"
        }
    ]
}